import React from 'react';
import PaymentMethodRegistration, { OptOutProps, OptOutSuccessProps } from './PaymentMethodRegistration';

export type SuccessReceiptLabels = {
   successTitle?: string;
   successMessage?: string;
   currencySymbol: string;
   totalCharged?: string;
   accountNumber?: string;
   paymentReference?: string;
}

export type SuccessReceiptDto = {
   reference: string;
   account: string;
   amount: number;
   cardRegistered: boolean;
}

export type SuccessReceiptProperties = {
   receipt: SuccessReceiptDto;
   labels: SuccessReceiptLabels;
   optOutProps: OptOutProps;
   optOutSuccessProps: OptOutSuccessProps;
}

export default function SuccessReceipt(props: SuccessReceiptProperties) {
   const { receipt, labels, optOutProps, optOutSuccessProps } = props;

   React.useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <React.Fragment>
         <div className="Receipt-container">
            <div className="flex-col heading-container">
               <span className="title">{labels?.successTitle}</span>
               <span className="value">{labels?.successMessage}</span>
            </div>
            <hr />
            <div className="receipt-details-container">
               <div className="flex-row account-total-container">
                  <label className="title">{labels?.totalCharged}</label>
                  <label className="value">{labels.currencySymbol}{receipt.amount.toFixed(2)}</label>
               </div>
               <div className="flex-row account-number-container">
                  <label className="title">{labels?.accountNumber}</label>
                  <label className="value">{receipt.account}</label>
               </div>
               <div className="flex-row account-payment-reference">
                  <label className="title">{labels?.paymentReference}</label>
                  <label className="value">{receipt.reference}</label>
               </div>
            </div>
         </div>
         {receipt.cardRegistered && (
            <div className='optout-section'>
               <PaymentMethodRegistration
                  optOutProps={optOutProps}
                  optOutSuccessProps={optOutSuccessProps}
               />
            </div>
         )}
      </React.Fragment>
   );
}