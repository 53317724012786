
import React, { FormEvent, RefObject, useRef, useState } from "react";
import { VerifyPasscodeDto } from "../../../api/dto/VerifyRp";

type VerifyErrorState = {
   error?: string;
   dobError?: string;
   maxAttemptsReached?: boolean;
}

export type VerifyRpLabels = {
   postcodeMessage?: string,
   dobMessage?: string,
   inputDay?: string,
   inputMonth?: string,
   inputYear?: string,
   inputPostcode?: string,
   buttonSubmit?: string,
}

export type VerifyErrorCallback = (error: string, maxAttemptsReached: boolean) => void;

let constants = {
   MIN_DAY: 1,
   MAX_DAY: 31,
   MIN_MONTH: 1,
   MAX_MONTH: 12,
   MIN_YEAR: 1900,
   MAX_YEAR: new Date().getFullYear(),
   ERROR_MSG_DAY: '',
   ERROR_MSG_MONTH: '',
   ERROR_MSG_YEAR: '',
   MAX_LENGTH: 20,
   ERROR_MSG_POSTCODE_BLANK: 'Postcode cannot be blank',
   ERROR_MSG_POSTCODE_LONG: 'Postcode is too long',
};
constants.ERROR_MSG_DAY = `Enter number between ${constants.MIN_DAY} - ${constants.MAX_DAY}`;
constants.ERROR_MSG_MONTH = `Enter number between ${constants.MIN_MONTH} - ${constants.MAX_MONTH}`;
constants.ERROR_MSG_YEAR = `Enter number between ${constants.MIN_YEAR} - ${constants.MAX_YEAR}`;

export const VerifyRpConstants = Object.freeze(constants);

export type VerifyRpProperties = {
   labels: VerifyRpLabels,
   dob?: boolean,
   postcode?: boolean,
   onSubmit: (passcodes: VerifyPasscodeDto[], onErrorCallback: VerifyErrorCallback) => void
}

function trimTextInput(ref: RefObject<HTMLInputElement>) {
   let value = ref.current?.value ?? "";
   return value.trim();
}

export default function VerifyRp(props: VerifyRpProperties) {
   let { labels, postcode, dob, onSubmit } = props;

   const isPostcodeEnabled = postcode ?? false;
   const isDobEnabled = dob ?? false;

   const dRef = useRef<HTMLInputElement>(null);
   const mRef = useRef<HTMLInputElement>(null);
   const yRef = useRef<HTMLInputElement>(null);
   const pRef = useRef<HTMLInputElement>(null);

   const defaultErrorState: VerifyErrorState = { error: '', dobError: '', maxAttemptsReached: false };
   const [errorState, setSubmissionErrorState] = useState(defaultErrorState);
   const onSubmission = (e: FormEvent) => {
      e.preventDefault();
      setSubmissionErrorState(defaultErrorState);

      var error: VerifyErrorState = {};
      let passcodes: VerifyPasscodeDto[] = [];
      if (isPostcodeEnabled) {
         const postcode = trimTextInput(pRef);
         if (postcode) {
            passcodes.push({ challenge: 'POSTCODE', value: postcode });
         }
         else {
            error.error = 'Please enter your Postcode';
         }
      }

      if (isDobEnabled) {
         let day = dRef.current?.valueAsNumber!;
         let month = mRef.current?.valueAsNumber!;
         let year = yRef.current?.valueAsNumber!;
         if (isNaN(day) || isNaN(month) || isNaN(year)) {
            error.dobError = 'Please enter your Date of Birth';
         }

         let dayStr = `${day}`.length === 1 ? `0${day}` : `${day}`;
         let monthStr = `${month}`.length === 1 ? `0${month}` : `${month}`;
         let dob = `${year}-${monthStr}-${dayStr}`;
         if (!isNaN(Date.parse(dob))) {
            passcodes.push({ challenge: 'DOB', value: dob });
         }
         else {
            error.dobError = 'Please enter your Date of Birth';
         }
      }

      if (error.error || error.dobError) {
         error.maxAttemptsReached = false;
         setSubmissionErrorState(error);
      }
      else {
         onSubmit(passcodes, (error, maxAttemptsReached) => setSubmissionErrorState({ error, maxAttemptsReached }));
      }
   };

   return (
      <form className="verify-rp-form" onSubmit={onSubmission}>
         <div className="verify-rp-div">
            {!isPostcodeEnabled ? undefined : (
               <React.Fragment>
                  <div className="verify-rp-message-container">
                     <span className="verify-rp-message">{labels.postcodeMessage}</span>
                  </div>
                  <div className="verify-rp-post-code-container">
                     <input ref={pRef} required type="text" autoComplete="off" spellCheck="false" autoCorrect="off" placeholder={labels.inputPostcode} />
                  </div>
                  <hr />
               </React.Fragment>
            )}
            {!isDobEnabled ? undefined : (
               <React.Fragment>
                  <div className="verify-rp-message-container">
                     <span>{labels.dobMessage}</span>
                  </div>
                  <div className="verify-rp-date-of-birth-container">
                     <input ref={dRef} className="day" required type="number" min={VerifyRpConstants.MIN_DAY} max={VerifyRpConstants.MAX_DAY} placeholder={labels.inputDay} />
                     <input ref={mRef} className="month" required type="number" min={VerifyRpConstants.MIN_MONTH} max={VerifyRpConstants.MAX_MONTH} placeholder={labels.inputMonth} />
                     <input ref={yRef} className="year" required type="number" min={VerifyRpConstants.MIN_YEAR} max={VerifyRpConstants.MAX_YEAR} placeholder={labels.inputYear} />
                  </div>
               </React.Fragment>
            )}
            <div className='flex-row verify-rp-error-container'>
               {errorState.error && (
                  <span>{errorState.error}</span>
               )}
            </div>
            <div className='flex-row verify-rp-error-container'>
               {errorState.dobError && (
                  <span className='flex-row'>{errorState.dobError}</span>
               )}
            </div>
            <div className="verify-rp-button-container">
               {!errorState.maxAttemptsReached && (
                  <button type="submit">{labels.buttonSubmit}</button>
               )}
            </div>
         </div>
      </form>
   );
}