import React, { useState, useEffect } from "react";
import { Typography, Modal, Button } from "@mui/material";
import { styled } from "@mui/system";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';

interface GenericModalProps {
    variant: "link" | "button";
    linkTitle: string;
    modalTitle: string;
    modalContent: string;
    actionButtonText?: string;
    onActionButtonClick?: () => Promise<void>;
}

const ModalContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4)
}));

export default function GenericModal({
    variant,
    linkTitle,
    modalTitle,
    modalContent,
    actionButtonText,
    onActionButtonClick,
}: GenericModalProps) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const processMarkdownText = (content: string) => {
        if (!content) return null;

        const parts = content.split(/(\n)/);
        return parts.map((part, index) => {
            if (part === '\n') {
                return <br key={`br-${index}`} />;
            }
            return (
                <span key={`text-${index}`}>
                    <ReactMarkdown
                        components={{
                            p: ({ children }) => <>{children}</>,
                            a: (props) => <a target="_blank" rel="noreferrer" {...props} />
                        }}
                    >
                        {part}
                    </ReactMarkdown>
                </span>
            );
        });
    };

    return (
        <>
            {variant === "link" ? (
                <>{' '}<span className="GenericModal open-link" onClick={handleOpen}>
                    {`${linkTitle}`}
                </span></>
            ) : (
                <button className="GenericModal open-button" onClick={handleOpen}>
                    {linkTitle}
                </button>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <ModalContainer className="GenericModal-container">
                    <div className="header-container">
                        <Typography className="title" variant="h6" component="h2">
                            {modalTitle}
                        </Typography>
                        <Button onClick={handleClose} className="close-button">
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </Button>
                    </div>
                    <div className="description react-markdown">
                        {processMarkdownText(modalContent)}
                    </div>
                    <div className="button-container">
                        {actionButtonText && onActionButtonClick && (
                            <Button
                                onClick={() => {
                                    handleClose();
                                    onActionButtonClick();
                                }}
                                sx={{ mt: 3 }}
                                className="action-button"
                            >
                                {actionButtonText}
                            </Button>
                        )}
                        <Button onClick={handleClose} className="close-button">
                            Close
                        </Button>
                    </div>
                </ModalContainer>
            </Modal>
        </>
    );
}