import React from 'react';
import ReactMarkdown from 'react-markdown';
import GenericModal from './GenericModal';

export type OptOutProps = {
  title: string;
  linkTitle?: string;
  externalLink?: string;
  message?: string;
  modalTitle?: string;
  modalVariant?: "link" | "button";
  modalContent?: string;
}

export type OptOutSuccessProps = {
  optOutHandler: () => Promise<void>;
  title: string;
  message: string;
};

type PaymentMethodRegistrationProps = {
  optOutProps: OptOutProps,
  optOutSuccessProps: OptOutSuccessProps
}

export default function PaymentMethodRegistration({
  optOutProps,
  optOutSuccessProps
}: PaymentMethodRegistrationProps) {
  const renderModal = (index: number) => {
    if (optOutProps.modalTitle && optOutProps.modalContent) {
      return (
        <GenericModal
          key={`modal-${index}`}
          variant={optOutProps.modalVariant || 'link'}
          linkTitle={optOutProps.linkTitle || 'Terms and Conditions'}
          modalTitle={optOutProps.modalTitle}
          modalContent={optOutProps.modalContent}
          actionButtonText="Opt-Out"
          onActionButtonClick={optOutSuccessProps.optOutHandler}
        />
      );
    }
    return null;
  };

  const processMarkdownText = (message: string) => {
    if (!message) return null;

    const parts = message.split(/(\{modal\}|\n)/);
    return parts.map((part, index) => {
      if (part === '{modal}') {
        return renderModal(index);
      }
      if (part === '\n') {
        return <br key={`br-${index}`} />;
      }
      return (
        <span key={`text-${index}`}>
          <ReactMarkdown
            key={`md-${index}`}
            components={{
              p: ({ children }) => <>{children}</>,
              a: (props) => <a target="_blank" rel="noreferrer" {...props} />
            }}
          >
            {part}
          </ReactMarkdown>
        </span>
      );
    });
  };

  return (
    <div className="OptOut-container">
      <div className="optout-header">{optOutProps.title}</div>
      <div className="optout-msg react-markdown">
        {processMarkdownText(optOutProps.message || '')}
      </div>
      <button className="optout-button" onClick={optOutSuccessProps.optOutHandler}>Opt-Out</button>
    </div>
  );
}