import { Divider } from '@mui/material';

type OptOutSuccessProps = {
  title: string;
  message: string;
}

export default function OptOutSuccess(props: OptOutSuccessProps) {
  return (
    <div className="OptOutSuccess-container" >
      <h2 className="optoutsuccess-label">{props.title}</h2>
      <Divider className='optoutsuccess-divider' />
      <div className="optoutsuccess-msg">{props.message}</div>
    </div>
  );
}